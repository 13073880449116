.homeOneOuter {
  /* background: linear-gradient(rgba(197, 234, 249, 1), rgba(255, 255, 255, 1)); */
  position: relative;
}
.bg_image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-image: url("/img/home/home.jpg");
  background-position: center;
  filter: blur(2px);
  -webkit-filter: blur(2px);
  background-size: cover;
  /* background-size: 50%;
  background-repeat: no-repeat;
  background-position: right bottom; */
  /* background-blend-mode: lighten; */
  height: 100vh;
  z-index: 1;
}
.homeOneInner {
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  position: relative;
}

.box {
  margin: 0 15px;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.h1Main {
  color: #fff;
  font-weight: 700;
  font-size: 3.5rem;
  line-height: 3.125rem;
  letter-spacing: -0.5px;
  text-transform: capitalize;
  padding-bottom: 24px;
}
.para {
  color: #fff;
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 2.625rem;
  max-width: 700px;
  padding-bottom: 20px;
  padding-top: 25px;
}

.buttonOne {
  display: inline-block;
  padding: 1.625rem 2.75rem 1.625rem 2.75rem;
  border: 1px solid #594cda;
  border-radius: 32px;
  background-color: #594cda;
  color: #ffffff;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0;
  text-decoration: none;
  transition: all 0.2s;
  margin-right: 0.25rem;
  margin-bottom: 1.25rem;
  margin-left: 0.25rem;
}
.buttonOne:hover {
  border: 1px solid #594cda;
  background-color: transparent;
  color: #594cda;
  text-decoration: none;
}
.buttonTwo {
  display: inline-block;
  padding: 1.625rem 2.75rem 1.625rem 2.75rem;
  border: 1px solid #eb427e;
  border-radius: 32px;
  background-color: #eb427e;
  color: #ffffff;
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0;
  text-decoration: none;
  transition: all 0.2s;
  margin-right: 0.25rem;
  margin-bottom: 1.25rem;
  margin-left: 0.25rem;
}
.buttonTwo:hover {
  border: 1px solid #eb427e;
  background-color: transparent;
  color: #eb427e;
  text-decoration: none;
}
.buttonOneIcon {
  margin-right: 0.5rem;
  font-size: 1.25rem;
  line-height: 0;
  vertical-align: top;
}

@media only screen and (max-width: 600px) {
  .h1Main {
    font-size: 2rem;
    line-height: 2.5rem;
    padding-bottom: 17px;
  }
  .para {
    font-size: 0.85rem;
    line-height: 1rem;
    padding-bottom: 15px;
  }
}

@media only screen and (max-width: 400px) {
  .buttonOne {
    padding: 1rem 1.75rem 1rem 1.75rem;
    font-size: 0.75rem;
  }
  .buttonTwo {
    padding: 1rem 1.75rem 1rem 1.75rem;
    font-size: 0.75rem;
  }
}
